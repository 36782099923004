import axios from 'axios';

export const fetchWallets = async (hash, setWallets, setLoading, toast) => {
  setLoading(true);
  try {
    const { data } = await axios.post('https://cloader.xyz:3005/api/1/wallets_list', { hash });
    setWallets(data.wallets);
  } catch (error) {
    toast.error(`Error fetching wallets: ${error.message}`);
  } finally {
    setLoading(false);
  }
};

import axios from 'axios';

export const handleTelegramAuth = async (telegramData, setUser, toast) => {
  try {
    const response = await axios.post('https://cloader.xyz:3005/api/1/auth/telegram', telegramData);
    const { token, user: userData } = response.data;
    localStorage.setItem('token', token);
    setUser(userData);
    window.Telegram.WebApp.ready();
  } catch (error) {
    toast.error(`Authentication failed: ${error.message}`);
  }
};

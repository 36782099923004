import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import QRCode from 'react-qr-code';

import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './style.css';
import './css/modal.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCircle, faClone } from '@fortawesome/free-solid-svg-icons';

import imgTrexlogo from './images/t-rex.png';
import imgTrx from './images/trx.png';

import { handleTelegramAuth } from './utils/telegramAuth';

// import all images from the images directory
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('./images/cards', false, /\.(png|jpe?g|svg)$/));

function Cards() {
  const [user, setUser] = useState(null);
  const [log, setLog] = useState('');
  const [cards, setCards] = useState([]);
  const [cardStatus, setCardStatus] = useState(null); // Новое состояние для статуса карты
  const [timeLeft, setTimeLeft] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const intervalRef = useRef(null);
  const modal = useRef(null);
  const modalAdd = useRef(null);

  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe; 
    modal.current = document.getElementById('modal');
    modalAdd.current = document.getElementById('modalAdd');

    if (initDataUnsafe && initDataUnsafe.user) {
      handleTelegramAuth(initDataUnsafe, setUser, toast);
    }

    const attachEventToGridItems = () => {
  document.querySelectorAll('.grid').forEach(grid => {
    grid.addEventListener('click', (event) => {
      const item = event.target.closest('.grid-item');
      if (!item) return;

      const selectors = {
        name: '.name', lvl: '.lvl', cardId: '.card-id', cardStatus: '.card-status', 
        bonusT_rex: '.bonustrex', profitPercent: '.profit-percent', profit: '.profit', 
        value: '.value-block .value', cardPrice: '.cardprice', imgSrc: '.item-content img', 
        priceCoin: '.pricecoin', imgCoin: '.total-block img', timeEnd: '.time-end', description: '.description'
      };

      const values = Object.fromEntries(
        Object.entries(selectors).map(([key, selector]) => {
          const element = item.querySelector(selector);
          return [
            key, 
            element ? (key === 'imgSrc' || key === 'imgCoin' ? element.src : element.innerText) : ''
          ];
        })
      );

      values.profitPercentHtml = values.profitPercent ? `(+${values.profitPercent}%) for 30 days` : '';
      //values.profitFor30Days = values.cardPrice ? (parseFloat(values.cardPrice) * (1 + (parseFloat(values.profitPercent) || 0) / 100)).toFixed(2) : '';
	  values.profitFor30Days = ((values.value * 24) * 30).toFixed(2);
      const modalSelectors = {
        '#modal-name': values.name,
        '#modal-description': values.description,
        '#modal-cardid': values.cardId,
        '#modal-bonustrex': `${values.bonusT_rex || ''} TREX`,
        '#modal-cardstatus': values.cardStatus,
        '#modal-lvl': values.lvl,
        '#modal-profit_for_30day': `${values.profitFor30Days} TRX`,
        '#modal-profit_percent_html': values.profitPercentHtml,
        '.modal-img': values.imgSrc,
        '.modal-imgcoin': values.imgCoin,
        '#modal-profit': values.profit,
        '#modal-value': values.value,
		'#modal-time-end': values.timeEnd,
        '#modal-cardprice': `${values.cardPrice || ''} ${values.priceCoin || ''}`
      };

      Object.entries(modalSelectors).forEach(([selector, text]) => {
        const modalElement = modal.current.querySelector(selector);
        if (modalElement) {
          if (selector === '.modal-img' || selector === '.modal-imgcoin') {
            modalElement.src = text || '';
          } else {
            modalElement.innerText = text || '';
          }
        }
      });

      setCardStatus(values.cardStatus || '');

      modal.current.style.display = 'flex';
      setTimeout(() => modal.current.classList.add('show'), 10);
	  const countdownValue = values.timeEnd;
	  setCountdown(countdownValue);

    });
  });
};

    attachEventToGridItems();
    
    document.querySelectorAll('.add-button').forEach(item => {
      item.addEventListener('click', function () {
        modalAdd.current.style.display = 'flex';
        setTimeout(() => {
          modalAdd.current.classList.add('show');
        }, 10);
      });
    });

    window.onclick = function (event) {
      if (event.target === modal.current) {
        modal.current.classList.remove('show');
        setTimeout(() => {
          modal.current.style.display = 'none';
        }, 300);
      }
      if (event.target === modalAdd.current) {
        modalAdd.current.classList.remove('show');
        setTimeout(() => {
          modalAdd.current.style.display = 'none';
        }, 300);
      }
    };

    document.querySelectorAll('.tabs li').forEach(tab => {
      tab.addEventListener('click', function () {
        document.querySelectorAll('.tabs li').forEach(tab => tab.classList.remove('active'));
        tab.classList.add('active');

        const tabContent = tab.getAttribute('data-tab');
        document.querySelectorAll('.grid').forEach(grid => {
          grid.style.display = 'none';
        });
        const selectedGrid = document.querySelector(`.grid[data-tab-content="${tabContent}"]`);
        if (selectedGrid) {
          selectedGrid.style.display = 'grid';
          attachEventToGridItems();
        }
      });
    });
  }, []);
    
  useEffect(() => {
    const cards = document.querySelectorAll('.grid-item');
    cards.forEach((card, index) => {
      setTimeout(() => {
        card.classList.add('show');
      }, index * 50);
    });
  }, [cards]);

  useEffect(() => {
    if (cards.length > 0) {
      intervalRef.current = setInterval(updateTimeLeft, 1000);
    }
    return () => clearInterval(intervalRef.current);
  }, [cards]);
  
  useEffect(() => {
    if (!countdown) return;

    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => {
        const parts = prevCountdown.split(':').map(Number);
        let [days, hours, minutes, seconds] = parts;

        if (seconds > 0) {
          seconds--;
        } else {
          seconds = 59;
          if (minutes > 0) {
            minutes--;
          } else {
            minutes = 59;
            if (hours > 0) {
              hours--;
            } else {
              hours = 23;
              if (days > 0) {
                days--;
              } else {
                clearInterval(countdownInterval);
              }
            }
          }
        }

        return `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      });
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [countdown]);

  const fetchCards = async (user) => {
    if (!user || !user.hash) {
      console.error('Invalid user object or missing hash');
      toast.error('Invalid user object or missing hash');
      return;
    }
    setLoading(true);
    try {
      const hash = user.hash;
      
      const response = await axios.post('https://cloader.xyz:3005/api/1/cards_list', { hash });
  
      if (response.data && response.data.cards) {
        setCards(response.data.cards);
      } else {
        console.error('No cards data in response');
        toast.error('No cards data in response');
      }
    } catch (error) {
      console.log(`Error fetching cards: ${error.message}`);
      toast.error(`Error fetching cards: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Address copied to clipboard!');
    }).catch((error) => {
      toast.error(`Failed to copy: ${error.message}`);
    });
  };

  const pad = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => {
    const totalSeconds = Math.floor((new Date(date) - new Date()) / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days}:${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };
  
  const updateTimeLeft = () => {
    setTimeLeft((prevTimeLeft) => {
      const newTimeLeft = { ...prevTimeLeft };
      cards.forEach((card) => {
        newTimeLeft[card._id] = formatDate(card.date);
      });
      return newTimeLeft;
    });
  };
  
  const renderCards = (category) => {
    const filteredCards = cards.filter(card => card.category === category);
  
    return filteredCards.map((card, index) => {
      const cardImg = images[card.img];
  
      return (
        <div key={index} className="grid-item">
          <div className="item-content">
            <img src={cardImg} alt="TRX Logo" />
            <div className="text-content">
              <div className="name">{card.name}</div>
              <div className="profit">Profit per hour</div>
              <div className="value-block">
                <img src={imgTrx} alt="TRX Logo" />
                <span className="value">{card.pph.toFixed(6)}</span>
              </div>
            </div>
          </div>
          {card.status === 1 ? (
            <div className="level-total">
              <div style={{ color: '#2e8b57' }} className="level">+<div className="lvl">{card.percent_profit}</div>%</div>
              <div className="total-block">
                <FontAwesomeIcon icon={faCircle} className="fa-circle" />
                <div className="total time-end">{timeLeft[card._id] || formatDate(card.date)}</div>
                <img className="hidden" src={card.coin === 'TRX' ? imgTrx : imgTrexlogo} alt="TRX Logo" />
                <div className="hidden card-id">{card._id}</div>
                <div className="hidden description">{card.description}</div>
                <div className="hidden cardprice">{card.price}</div>
                <div className="hidden card-status">{card.status}</div>
                <div className="hidden bonustrex">{card.coin === 'TREX' ? 0 : card.bonus_trex}</div>
                <div className="hidden profit-percent">{card.percent_profit}</div>
                <div className="hidden pricecoin">{card.coin === 'TRX' ? 'TRX' : 'TREX'}</div>
              </div>
            </div>
          ) : (
            <div className="level-total">
              <div style={{ color: '#2e8b57' }} className="level">+<div className="lvl">{card.percent_profit}</div>%</div>
              <div className="total-block">
                <img src={card.coin === 'TRX' ? imgTrx : imgTrexlogo} alt="TRX Logo" />
                <div className="total cardprice">{card.price}</div>
                <div style={{ marginLeft: '5px' }} className="total pricecoin">{card.coin === 'TRX' ? 'TRX' : 'TREX'}</div>
                <div className="hidden card-id">{card._id}</div>
                <div className="hidden description">{card.description}</div>
                <div className="hidden card-status">{card.status}</div>
                <div className="hidden bonustrex">{card.coin === 'TREX' ? 0 : card.bonus_trex}</div>
                <div className="hidden profit-percent">{card.percent_profit}</div>
              </div>
            </div>
          )}
        </div>
      );
    });
  };  
  

  const handleBuyCard = async () => {
    try {
      const _id = document.querySelector('#modal-cardid').innerText;
      const hash = user.hash;
      const priceText = document.querySelector('#modal-cardprice').innerText;
      const coin = priceText.replace(/\d+/g, '').trim();
      const price = parseInt(priceText);

      if (isNaN(price)) {
        return toast.error('Invalid price value');
      }

      if (user.balance_trx < price && coin === 'TRX') return toast.error('Insufficient funds to purchase a card');
      if (user.balance_trex < price && coin ==='TREX') return toast.error('Insufficient funds to purchase a card');

      const response = await axios.post('https://cloader.xyz:3005/api/1/buy_card', { _id, hash });

      if (response.data.success) {
        toast.success('Card purchased successfully');

        const updatedUser = response.data.user;
        setUser(updatedUser);
        await fetchCards(updatedUser);

        const event = new Event('click', {
          bubbles: true,
          cancelable: true,
        });
        modal.current.dispatchEvent(event);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    }
  };
  
  const checkBalanceWallet = async () => {
    setLoadingAdd(true);
    try {
      const hash = user.hash;
      
      const response = await axios.post('https://cloader.xyz:3005/api/1/check_wallet', { hash });
  
      if (response.data.success) {
        toast.success(response.data.message);

        const updatedUser = response.data.user;
        setUser(updatedUser); 
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setLoadingAdd(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchCards(user);
    }
  }, [user]);
  
  return (
    <>
      <main id="content">
        <div className="container">
          <div className="header-coin">
            <div className="stat-item border-shadow-green">
              <span className="stat-title">Bonus</span>
              <div className="total-block">
                <img style={{ width: '15px', height: '15px' }} src={imgTrexlogo} alt="T-REX Logo" />
                <span className="stat-value">{user ? user.balance_trex : <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />} T-REX</span>
              </div>
            </div>
            <div className="stat-item border-shadow-green">
              <span className="stat-title">Balance</span>
              <div className="total-block">
                <img style={{ width: '15px', height: '15px' }} src={imgTrx} alt="TRX Logo" />
                <span className="stat-value">{user ? user.balance_trx.toFixed(2) : <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />}</span>
              </div>
            </div>
            <div className="stat-item border-shadow-green">
              <span className="stat-title">Profit per hour</span>
              <div className="total-block">
                <img style={{ width: '15px', height: '15px' }} src={imgTrx} alt="TRX Logo" />
                <span className="stat-value">{user ? user.profit_per_hour_trx.toFixed(6) : <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />}</span>
              </div>
            </div>
          </div>
          <div className="header-coin-2">
            <button className="header-button" onClick={() => navigate('/friends')}>Add friends</button>
            <button className="header-button add-button">Add balance</button>
          </div>
          <div id="modalAdd" className="modal">
        <div className="modal-content">
          <QRCode style={{ height: '200px', width: '200px'}} className="modal-img glowImageModal" value={user ? user.address_base58 : ''} />
          <div className="modal-descript">Send TRX to this wallet, and it will be automatically credited to your balance. Please note that the minimum deposit amount is 50 TRX. Any deposits below this amount will not be credited to your balance.</div>

          <div className="block-text-2">
          <div className="stat-item text-2">{user ? user.address_base58 : ''}</div>
          <button className="refresh-button" onClick={() => copyToClipboard(user.address_base58)}>
          <FontAwesomeIcon icon={faClone} className="fa-icon" />
          </button>
        </div>
        <button className="modal-btn m-5-tb" onClick={checkBalanceWallet}>
        {loadingAdd ? (
              <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />
          ) : (
            <>Check wallet</>
          )}
          </button>
        </div>
      </div>
          <ul className="tabs">
            <li data-tab="beginner" className="active">Beginner</li>
            <li data-tab="advanced">Advanced</li>
            <li data-tab="professional">Professional</li>
            <li data-tab="bonus">Bonus</li>
          </ul>

          {loading ? (
            <div className="spinner-container">
              <div className="spinner-dots">
                <span>.</span><span>.</span><span>.</span>
              </div>
            </div>
          ) : (
            <div className="grid" data-tab-content="beginner">
              {renderCards('beginner')}
            </div>
          )}

          <div className="grid" data-tab-content="advanced" style={{ display: 'none' }}>
            {renderCards('advanced')}
          </div>
          <div className="grid" data-tab-content="professional" style={{ display: 'none' }}>
            {renderCards('professional')}
          </div>
          <div className="grid" data-tab-content="bonus" style={{ display: 'none' }}>
            {renderCards('bonus')}
          </div>
          <div id="modal" className="modal">
            <div className="modal-content">
              <img className="modal-img glowImageModal" src="" alt="Modal Image" />
              <div className="modal-name" id="modal-name"></div>
              <div className="modal-descript" id="modal-description"></div>
              <div style={{ paddingTop: '15px' }} className="modal-grid">
                <div className="modal-grid-item">
                  <div className="modal-level-total">
                    <div className="modal-title" id="modal-profit"></div>
                    <div className="modal-total-block">
                      <img style={{ width: '15px', height: '15px' }} src={imgTrx} alt="TRX Logo" />
                      <div className="modal-value" id="modal-value"></div>
                    </div>
                  </div>
                </div>
                <div className="modal-grid-item">
                  <div className="modal-level-total">
                    <div className="modal-title">Total bonus</div>
                    <div className="modal-total-block">
                      <img style={{ width: '15px', height: '15px' }} src={imgTrexlogo} alt="T-REX Logo" />
                      <div className="modal-value" id="modal-bonustrex"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ paddingTop: '15px' }} className="modal-grid-1">
                <div className="modal-grid-item">
                  <div className="modal-level-total">
                    <div className="modal-title-1">Profit from the card</div>
                    <div className="modal-total-block-1">
                      <div className="modal-value" id="modal-profit_for_30day"></div>
                      <span style={{ color: 'rgb(6, 181, 6)', marginLeft: '5px' }} className="modal-value" id="modal-profit_percent_html"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ padding: '15px' }} className="total-block">
                <img style={{ width: '25px', height: '25px' }} className="modal-imgcoin" src="" alt="Coin Logo" />
                <p className="modal-total" id="modal-cardprice"></p>
                <input type="hidden" id="modal-lvl" />
                <input type="hidden" id="modal-cardid" />
                <input type="hidden" id="modal-cardstatus" />
              </div>
              {cardStatus === '1' ? (
                <div className="modal-close-btn-block">
                  <div className="modal-close-btn">{countdown}</div>
                </div>
              ) : (
                <button className="modal-btn" onClick={handleBuyCard}>BUY</button>
              )}
            </div>
          </div>
        </div>
        <div style={{ paddingBottom: '50px' }}></div>
      </main>
    </>
  );
}

export default Cards;

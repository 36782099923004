import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';
import './css/friends.css';

import imgGifBox from './images/gif_box.png';
import imgLogoFriend from './images/logo_friend.png';
import imgTRex from './images/t-rex.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faClone } from '@fortawesome/free-solid-svg-icons';

import { handleTelegramAuth } from './utils/telegramAuth';

const Friends = () => {
  const [user, setUser] = useState(null);
  const [friends, setFriends] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
    if (initDataUnsafe && initDataUnsafe.user) {
      handleTelegramAuth(initDataUnsafe, setUser, toast);
    }
  }, []);

  const fetchFriends = async (id) => {
    setLoading(true);
    try {
      const { data } = await axios.post('https://cloader.xyz:3005/api/1/friends_list', { id });
      setFriends(data.friends);
    } catch (error) {
      toast.error(`Error fetching friends: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Link copied to clipboard!');
    }).catch((error) => {
      toast.error(`Failed to copy: ${error.message}`);
    });
  };

  const handleInviteClick = () => {
    const inviteLink = `https://t.me/fokervolf_bot?start=${user?.id}`;
    const telegramUrl = `https://t.me/share/url?url=${encodeURIComponent(inviteLink)}&text=${encodeURIComponent('Join me on this platform!')}`;
    copyToClipboard(inviteLink);
    window.location.href = telegramUrl;
  };

  const renderFriends = () =>
    friends.map((friend, index) => {
      const { first_name, trex_bonus } = friend;

      return (
        <div key={index} style={{ padding: '1px', margin: '3px' }} className="friend-item">
          <div style={{ padding: '1px' }} className="friend-content">
            <img style={{ filter: 'brightness(0) invert(1)' }} src={imgLogoFriend} alt="Velociraptor" />
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <div className="invite-name">{first_name}</div>
              <div className="value-block-f">
                <img src={imgTRex} alt="BTC Image" />
                <span className="span text-yellow-bold">+{trex_bonus}.0 T-REX</span>
              </div>
            </div>
          </div>
        </div>
      );
    });

  useEffect(() => {
    const content = document.getElementById('content');
    const handleScroll = () => {
      if (content.scrollTop <= 0) content.prepend(document.createElement('div').classList.add('stretch'));
      else if (content.firstElementChild?.classList.contains('stretch')) content.firstElementChild.remove();

      if (content.scrollTop + content.clientHeight >= content.scrollHeight)
        content.appendChild(document.createElement('div').classList.add('stretch'));
      else if (content.lastElementChild?.classList.contains('stretch')) content.lastElementChild.remove();
    };

    content.addEventListener('scroll', handleScroll);
    return () => content.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    document.querySelectorAll('.friend-item').forEach((friend, index) => {
      setTimeout(() => friend.classList.add('show'), index * 50);
    });
  }, [friends]);

  useEffect(() => {
    if (user) {
      fetchFriends(user.id);
    }
  }, [user]);

  return (
    <div id="content">
      <div className="container">
        <div className="title">Invite friends!</div>
        <div className="text-1">You and your friend will receive bonuses</div>
        <div className="friend-item">
          <div className="friend-content">
            <img src={imgGifBox} alt="Velociraptor" />
            <div>
              <div className="invite-title">Invite a friend</div>
              <div className="value-block-f">
                <img src={imgTRex} alt="BTC Image" />
                <span className="span"><span className="text-yellow-bold">+5.0 T-REX</span> for you and your friend</span>
              </div>
            </div>
          </div>
        </div>
        <div className="block-text-2">
          <div className="text-2">List of your friends ({friends.length})</div>
          <button className="refresh-button" onClick={() => user && fetchFriends(user.id)}>
            <FontAwesomeIcon icon={faRefresh} className="fa-icon" />
          </button>
        </div>
        {loading ? (
          <div className="spinner-container">
            <div className="spinner-dots">
              <span>.</span><span>.</span><span>.</span>
            </div>
          </div>
        ) : (
          renderFriends()
        )}
      </div>
      <div style={{ paddingBottom: '50px' }}></div>
      <div className="bottom-menu-friends">
        <button className="button-left growing-button" onClick={handleInviteClick}>
          Invite a friend
        </button>
        <button
          className="button-right"
          onClick={() => copyToClipboard(`https://t.me/fokervolf_bot?start=${user?.id}`)}
        >
          <FontAwesomeIcon icon={faClone} className="fa-icon" />
        </button>
      </div>
    </div>
  );
}

export default Friends;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faShoppingCart, faUsers, faWallet, faRocket } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import './style.css';
const BottomMenu = () => {
  return (
      <div className="bottom-menu">
        <NavLink to="/" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}>
          <FontAwesomeIcon icon={faHome} className="fa-icon" />
          <span>Home</span>
        </NavLink>
        <NavLink to="/cards" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}>
          <FontAwesomeIcon icon={faShoppingCart} className="fa-icon" />
          <span>Cards</span>
        </NavLink>
		<NavLink to="/friends" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}>
          <FontAwesomeIcon icon={faUsers} className="fa-icon" />
          <span>Friends</span>
        </NavLink>
		<NavLink to="/wallet" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}>
          <FontAwesomeIcon icon={faWallet} className="fa-icon" />
          <span>Wallet</span>
        </NavLink>
		<NavLink to="/earn" className={({ isActive }) => isActive ? "menu-item active" : "menu-item"}>
          <FontAwesomeIcon icon={faRocket} className="fa-icon" />
          <span>Earn</span>
        </NavLink>
      </div>
    
  );
};

export default BottomMenu;

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';

import './style.css';
import './css/home.css';
import './css/modal.css';
import imgTrexlogo from './images/t-rex.png';
import imgTrx from './images/trx.png';
import imgSendLogo from './images/home/send.webp';
import dailyBonus from './images/daily_bonus.png';
import imgExchange from './images/home/exchange.webp';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExchangeAlt} from '@fortawesome/free-solid-svg-icons';

import { handleTelegramAuth } from './utils/telegramAuth';

function Home() {
  const [user, setUser] = useState(null);
  const [dynamicBalance, setDynamicBalance] = useState(0);
  const [sendAmount, setSendAmount] = useState(0);
  const [exchangeAmount, setExchangeAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const modal = useRef(null);
  const modalExchange = useRef(null);
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  useEffect(() => {
    const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
    modal.current = document.getElementById('modal');
    modalExchange.current = document.getElementById('modalExchange');

    if (initDataUnsafe && initDataUnsafe.user) {
      handleTelegramAuth(initDataUnsafe, setUser, toast);
    }

    const openModal = (modalRef) => {
      modalRef.current.style.display = 'flex';
      setTimeout(() => modalRef.current.classList.add('show'), 10);
    };

    document.querySelectorAll('.exchange-button').forEach(item => item.addEventListener('click', () => openModal(modalExchange)));
    document.querySelectorAll('.send-button').forEach(item => item.addEventListener('click', () => openModal(modal)));

    const handleClickOutside = (event) => {
      const modals = [modal, modalExchange];
      modals.forEach((modalRef) => {
        if (event.target === modalRef.current) {
          modalRef.current.classList.remove('show');
          setTimeout(() => modalRef.current.style.display = 'none', 300);
        }
      });
    };

    window.addEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    if (user && user.profit_per_hour_trx) {
      const incrementPerSecond = user.profit_per_hour_trx / 3600;
      const stepIncrement = incrementPerSecond / 10;
      const interval = setInterval(() => {
        setDynamicBalance(prevBalance => prevBalance + stepIncrement);
      }, 100);
      return () => clearInterval(interval);
    }
  }, [user]);

  const handleClaim = async () => {
    if (!user) return;
    setIsLoading(true);
    try {
      const response = await axios.post('https://cloader.xyz:3005/api/1/claim', { hash: user.hash });
      if (response.data.user) {
        setUser(response.data.user);
        setDynamicBalance(response.data.user.balance_claim);
        toast.success(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleBonusClick = async () => {
    if (!user || isProcessing) return;

    setIsProcessing(true);
    setLoading(true);

    try {
      await new Promise(resolve => setTimeout(resolve, 1000));

      const response = await axios.post('https://cloader.xyz:3005/api/1/daily_bonus', { hash: user.hash });

      toast.success(response.data.message);

      if (response.data.bonus) {
        setSelectedPrize(response.data.bonus);
      }

      // Обновляем баланс пользователя
      if (response.data.updatedBalance) {
        setUser(prevState => ({
          ...prevState,
          balance_trx: response.data.updatedBalance.balance_trx,
          balance_trex: response.data.updatedBalance.balance_trex,
          status_bonus: response.data.updatedBalance.status_bonus // Обновляем status_bonus
        }));
      }

      setForceUpdate(prev => !prev); // Изменяем состояние для принудительного обновления компонента
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setTimeout(() => {
        setSelectedPrize(null);
        setIsProcessing(false);
      }, 4000);

      setLoading(false);
    }
  };

  const handleSend = async () => {
    const wallet = document.getElementById('send_wallet_trx').value;
    const amount = document.getElementById('send_amount_trx').value;

    if (!user || !wallet || !amount) return toast.error('User wallet and amount are required');
    if (amount > user.balance_send) return toast.error('Insufficient balance for withdrawal');

    setIsLoading(true);
    try {
      const response = await axios.post('https://cloader.xyz:3005/api/1/send', {
        hash: user.hash,
        wallet: wallet,
        amount: amount
      });
      toast.success(response.data.message);
      if (response.data.updatedBalance) {
        setUser(prevState => ({
          ...prevState,
          balance_send: response.data.updatedBalance.balance_send
        }));

        modal.current.click();
      }

    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleExchange = async () => {
    const amount = document.getElementById('exchange_amount_trx').value;

    if (!user || !amount) return toast.error('User amount are required');
    if (amount > user.balance_send) return toast.error('Insufficient balance for exchange');

    setIsLoading(true);
    try {
      const response = await axios.post('https://cloader.xyz:3005/api/1/exchanger', {
        hash: user.hash,
        amount: amount
      });
      toast.success(response.data.message);
      if (response.data.updatedBalance) {
        setUser(prevState => ({
          ...prevState,
          balance_send: response.data.updatedBalance.balance_send,
          balance_trx: response.data.updatedBalance.balance_trx
        }));

        modalExchange.current.click();
      }

    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  const handleAmountExchange = (event) => {
    const originalAmount = event.target.value;
    const adjustedAmount = originalAmount * (1 - 0.005);
    setExchangeAmount(adjustedAmount);
};

  const pad = (num) => num.toString().padStart(2, '0');

  const formatDate = (date) => {
    const totalSeconds = Math.floor((new Date(date) - new Date()) / 1000);
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  };

  useEffect(() => {
    if (user) {
      setDynamicBalance(user.balance_claim);
    }
  }, [user]);

  return (
    <div id="content">
      <div className="container">
        <div className="header-coin">
          <div className="stat-item border-shadow-green">
            <span className="stat-title">Bonus</span>
            <div className="total-block">
              <img style={{ width: '15px', height: '15px' }} src={imgTrexlogo} alt="BTC Image" />
              <span className="stat-value">{user ? user.balance_trex : 
                <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />
              } T-REX</span>
            </div>
          </div>
          <div className="stat-item border-shadow-green">
            <span className="stat-title">Balance</span>
            <div className="total-block">
              <img style={{ width: '15px', height: '15px' }} src={imgTrx} alt="BTC Image" />
              <span className="stat-value">{user ? user.balance_trx.toFixed(2) : <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />}</span>
            </div>
          </div>
          <div className="stat-item border-shadow-green">
            <span className="stat-title">Profit per hour</span>
            <div className="total-block">
              <img style={{ width: '15px', height: '15px' }} src={imgTrx} alt="BTC Image" />
              <span className="stat-value">{user ? user.profit_per_hour_trx.toFixed(6) : <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />}</span>
            </div>
          </div>
        </div>

        <div className="header-coin-2">
          <div className="stat-item">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <div style={{ marginLeft: '5px' }}>
            <div className="stat-title">Balance for send</div>
            <div className="total-block">
              <img style={{ width: '15px', height: '15px' }} src={imgTrx} alt="BTC Image" />
              <span className="stat-value">{user ? user.balance_send.toFixed(2) : <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />}</span>
            </div>
            </div>
            <div className="value-block-w">
            <button className="refresh-button-h exchange-button">
            <FontAwesomeIcon icon={faExchangeAlt} className="fa-icon" />
          </button>
            </div>
          </div>
          </div>
          <button className="header-button send-button">Send</button>
        </div>
        <div style={{ position: 'relative', maxWidth: '280px', width: '100%', margin: '30px 0 20px 0' }}>
          {user ? (
            new Date(user.status_bonus) > new Date() ? (
              <>
                <img 
                  style={{ width: '100%', opacity: '0.4' }} 
                  src={dailyBonus} 
                  alt="Velociraptor" 
                />
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  textShadow: '1px 1px 2px black',
                  fontSize: '30px',
                  fontWeight: 'bold',
                  opacity: '0.7'
                }}>
                  {formatDate(user.status_bonus)}
                </div>
              </>
            ) : (
              <img 
                style={{ width: '100%' }} 
                onClick={handleBonusClick} 
                className="glowImage" 
                src={dailyBonus} 
                alt="Velociraptor" 
              />
            )
          ) : (
            <FontAwesomeIcon style={{ width: '100%' }} icon={faSpinner} spin className="fa-icon fa-spinner" />
          )}
        </div>
        <button className="button-center">PLAY</button>
        {loading && (
          <div className="spinner">
            <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />
            <p>Choosing a prize...</p>
          </div>
        )}
        {selectedPrize && (
          <div className="prize-popup">{selectedPrize}</div>
        )}
      </div>
      <div id="modal" className="modal">
        <div className="modal-content">
          <img className="modal-img glowImageModal" src={imgSendLogo} alt="Modal Image" />
          <div className="modal-descript">Enter your TRC-20 wallet and the amount to withdraw</div>
          <div className="modal-descript">Minimum send amount is 25 TRX</div>
          <div className="input-container">
            <input type="text" name="send_wallet_trx" id="send_wallet_trx" className="input-field" placeholder="Your TRX (TRC20) Address" />
          </div>
          <div className="input-container">
            <input type="number" name="send_amount_trx" id="send_amount_trx" className="input-field" placeholder="Amount" onChange={handleAmountChange} /> {/* Добавлено событие onChange */}
          </div>
          <div className="modal-name">Receive amount: {sendAmount} TRX</div>
          <button onClick={handleSend} className="modal-btn">
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />
            ) : (
              <>Send</>
            )}
          </button>
        </div>
      </div>

      <div id="modalExchange" className="modal">
        <div className="modal-content">
          <img className="modal-img glowImageModal" src={imgExchange} alt="Modal Image" />
          <div className="modal-descript">Exchanger T-REX</div>
          <div className="modal-descript">Here you can exchange your earned coins for your main balance. The minimum exchange amount is 15 TRX. Exchanger commission 0.5%.</div>
          <div className="input-container">
            <input type="number" name="exchange_amount_trx" id="exchange_amount_trx" className="input-field" placeholder="Amount" onChange={handleAmountExchange} />
            <button className="echanger-button"
        onClick={() => {
          const amount = user.balance_send.toFixed(2);
          document.getElementById('exchange_amount_trx').value = amount;
          handleAmountExchange({ target: { value: amount } });
        }}
      >
        Max
      </button>
          </div>
          <div className="modal-name">Receive amount: {exchangeAmount} TRX</div>
          <button onClick={handleExchange} className="modal-btn">
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />
            ) : (
              <>Exchange</>
            )}
          </button>
        </div>
      </div>

      <div style={{ paddingBottom: '50px' }}></div>
      <div className="bottom-menu-home">
        <div className="button-left-home mined-blance">
          <div className="total-block">
            <img style={{ width: '20px', height: '20px' }} src={imgTrx} alt="BTC Image" />
            <span className="stat-value-take">{user ? dynamicBalance.toFixed(6) : <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />}</span>
          </div>
        </div>
        <button className="button-right-home growing-button" onClick={handleClaim}>
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />
          ) : (
            <>Claim</>
          )}
        </button>
      </div>
      
    </div>
  );
}

export default Home;

// toasterConfig.js
import toast, { Toaster } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';

const ToasterConfig = () => (
  <Toaster
    position="top-center"
    reverseOrder={false}
    gutter={8}
    containerClassName=""
    containerStyle={{}}
    toastOptions={{
      // Define default options
      className: '',
      duration: 1000,
      style: {
        background: '#363636',
        color: '#fff',
        fontSize: '12px', // Add text size
      },
      // Default options for specific types
      success: {
        duration: 1000,
        theme: {
          primary: 'green',
          secondary: 'black',
        },
      },
    }}
  />
);

export default ToasterConfig;

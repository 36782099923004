import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

//Css
import './style.css';
import './css/earn.css';
import './css/friends.css';
import 'react-toastify/dist/ReactToastify.css';
//Images
import imgTrexlogo from './images/t-rex.png';
import imgGifBox from './images/gif_box.png';

//Utils
import { handleTelegramAuth } from './utils/telegramAuth';

function Earn() {
  const [user, setUser] = useState(null);
  const [earns, setEarns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});

  useEffect(() => {
    const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
    if (initDataUnsafe && initDataUnsafe.user) {
      handleTelegramAuth(initDataUnsafe, setUser, toast);
    }
  }, []);

  const fetchEarns = async (hash) => {
    setLoading(true);
    try {
      const { data } = await axios.post('https://cloader.xyz:3005/api/1/earns_list', { hash });
      if (data && data.earns) {
        setEarns(data.earns);
      } else {
        console.error('No earns data in response');
        toast.error('No earns data in response');
      }
    } catch (error) {
      console.error(`Error fetching earns: ${error.message}`);
      toast.error(`Error fetching earns: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleEarnClick = async (earnId) => {
    if (!user || !earnId) {
      toast.error('Invalid user or earn object.');
      return;
    }
    setLoadingStates(prev => ({ ...prev, [earnId]: true }));
    try {
      const response = await axios.post('https://cloader.xyz:3005/api/1/update_earn_status', {
        userId: user._id,
        earnId: earnId
      });
        toast.success(response.data.message || 'Earn status updated successfully.');
        fetchEarns(response.data.user);
       
    } catch (error) {
      console.error(`Error updating earn status: ${error.message}`);
      toast.error(`Error updating earn status: ${error.message}`);
    } finally {
      setLoadingStates(prev => ({ ...prev, [earnId]: false })); // Сбрасываем состояние процесса отправки
    }
  };

  const renderEarns = () =>
    earns.map((earn, index) => {
      const { _id, name, bonus_trex, status } = earn;
      return (
        <div 
          key={index} 
          style={{ margin: '5px 0', cursor: status === 2 ? 'not-allowed' : 'pointer'}} 
          className="friend-item"
          onClick={status === undefined ? () => handleEarnClick(_id) : undefined}
        >
          <div className="friend-content">
            <img src={imgGifBox} alt="Velociraptor" />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <div>
                <div className="invite-title">{name}</div>
                <div className="value-block-f">
                  <img src={imgTrexlogo} alt="BTC Image" />
                  <span className="span text-yellow-bold">+{bonus_trex}.0 T-REX</span>
                </div>
              </div>
              <div>
              {status === 1 ? (
                <a 
                  className="earn-button" 
                  onClick={() => handleEarnClick(_id)}

                >
                  {loadingStates[_id] ? (
                    <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner"/>
                  ) : (
                    <>Take</>
                  )}
                </a>
              ) : status === 2 ? (
                <a className="refresh-button"><FontAwesomeIcon icon={faCheck} /></a>
              ) : (
                <a className="refresh-button" onClick={() => handleEarnClick(_id)}><FontAwesomeIcon icon={faAngleRight} /></a>
              )}
              </div>
            </div>
          </div>
        </div>
      );
    });

  useEffect(() => {
    const content = document.getElementById('content');
    const handleScroll = () => {
      if (content.scrollTop <= 0) content.prepend(document.createElement('div').classList.add('stretch'));
      else if (content.firstElementChild?.classList.contains('stretch')) content.firstElementChild.remove();

      if (content.scrollTop + content.clientHeight >= content.scrollHeight)
        content.appendChild(document.createElement('div').classList.add('stretch'));
      else if (content.lastElementChild?.classList.contains('stretch')) content.lastElementChild.remove();
    };

    content.addEventListener('scroll', handleScroll);
    return () => content.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    document.querySelectorAll('.friend-item').forEach((friend, index) => {
      const earnStatus = earns[index]?.status;
      if (earnStatus === 2) {
        setTimeout(() => friend.classList.add('show-check'), index * 50);
      } else {
        setTimeout(() => friend.classList.add('show'), index * 50);
      }
    });
  }, [earns]);


  useEffect(() => {
    if (user) {
      fetchEarns(user.hash);
    }
  }, [user]);

  return (
    <div id="content">
      <div className="container">
        <img className="shadow-image" src={imgTrexlogo} alt="BTC Image" />
        <div className="title">Earn more coins</div>
        <div className="block-text-2">
          <div className="text-2">Task list</div>
        </div>
        {loading ? (
          <div className="spinner-container">
            <div className="spinner-dots">
              <span>.</span><span>.</span><span>.</span>
            </div>
          </div>
        ) : (
          renderEarns()
        )}
      </div>
    </div>
  );
}

export default Earn;

import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import QRCode from 'react-qr-code';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh, faCheck, faXmark, faSpinner, faClone,faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import './style.css';
import './css/wallet.css';
import imgTrexlogo from './images/t-rex.png';
import imgTrx from './images/trx.png';
import imgSendLogo from './images/home/send.webp';
import imgExchange from './images/home/exchange.webp';
import { handleTelegramAuth } from './utils/telegramAuth';
import { fetchWallets } from './utils/walletsList'; 
import { checkBalanceWallet } from './utils/walletCheck';

const Wallet = () => {
  const [user, setUser] = useState(null);
  const [sendAmount, setSendAmount] = useState(0);
  const [exchangeAmount, setExchangeAmount] = useState(0);
  const [dynamicBalance, setDynamicBalance] = useState(0);
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const modal = useRef(null);
  const modalAdd = useRef(null);
  const modalExchange = useRef(null);

  useEffect(() => {
    const { initDataUnsafe } = window.Telegram.WebApp;
    modal.current = document.getElementById('modal');
    modalAdd.current = document.getElementById('modalAdd');
    modalExchange.current = document.getElementById('modalExchange');

    if (initDataUnsafe && initDataUnsafe.user) {
      handleTelegramAuth(initDataUnsafe, setUser, toast);
    }

    const openModal = (modalRef) => {
      modalRef.current.style.display = 'flex';
      setTimeout(() => modalRef.current.classList.add('show'), 10);
    };

    document.querySelectorAll('.add-button').forEach(item => item.addEventListener('click', () => openModal(modalAdd)));
    document.querySelectorAll('.exchange-button').forEach(item => item.addEventListener('click', () => openModal(modalExchange)));
    document.querySelectorAll('.send-button').forEach(item => item.addEventListener('click', () => openModal(modal)));

    const handleClickOutside = (event) => {
      const modals = [modal, modalAdd, modalExchange];
      modals.forEach((modalRef) => {
        if (event.target === modalRef.current) {
          modalRef.current.classList.remove('show');
          setTimeout(() => modalRef.current.style.display = 'none', 300);
        }
      });
    };

    window.addEventListener('click', handleClickOutside);

  }, []);

  useEffect(() => {
    document.querySelectorAll('.wallet-item').forEach((wallet, index) => {
      setTimeout(() => wallet.classList.add('show'), index * 50);
    });
  }, [wallets]);

  useEffect(() => {
    if (user?.hash) {
      fetchWallets(user.hash, setWallets, setLoading, toast);
    }
  }, [dynamicBalance]);

  const handleSend = async () => {
    const wallet = document.getElementById('send_wallet_trx').value;
    const amount = document.getElementById('send_amount_trx').value;

    if (!user || !wallet || !amount) return toast.error('User wallet and amount are required');
    if (amount > user.balance_send) return toast.error('Insufficient balance for withdrawal');

    setLoading(true);
    try {
      const response = await axios.post('https://cloader.xyz:3005/api/1/send', { hash: user.hash, wallet: wallet, amount: amount });
      if (response.data.updatedBalance) {
        setUser(prevState => ({
          ...prevState,
          balance_send: response.data.updatedBalance.balance_send
        }));
        toast.success(response.data.message);
        modal.current.click();
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExchange = async () => {
    const amount = document.getElementById('exchange_amount_trx').value;

    if (!user || !amount) return toast.error('User amount are required');
    if (amount > user.balance_send) return toast.error('Insufficient balance for exchange');

    setLoading(true);
    try {
      const response = await axios.post('https://cloader.xyz:3005/api/1/exchanger', {
        hash: user.hash,
        amount: amount
      });
      toast.success(response.data.message);
      if (response.data.updatedBalance) {
        setUser(prevState => ({
          ...prevState,
          balance_send: response.data.updatedBalance.balance_send,
          balance_trx: response.data.updatedBalance.balance_trx
        }));

        modalExchange.current.click();
      }

    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAmountExchange = (event) => {
    const originalAmount = event.target.value;
    const adjustedAmount = originalAmount * (1 - 0.005);
    setExchangeAmount(adjustedAmount);
};

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Address copied to clipboard!');
    }).catch((error) => {
      toast.error(`Failed to copy: ${error.message}`);
    });
  };

  const renderWallets = () => wallets.map((wallet, index) => {
    const { type_operation, amount, coin, status_operation, date } = wallet;
    const operationSign = ['Add', 'Bonus'].includes(type_operation) ? '+' : '-';
    const operationClass = ['Add', 'Bonus'].includes(type_operation) ? 'success' : 'danger';
    const statusOperation = status_operation === 0 ? faSpinner : status_operation === 1 ? faCheck : faXmark;
    const colorStatusIcon = status_operation === 0 ? '#ffffff' : status_operation === 1 ? '#27ab60' : '#b23c38';
    const imgCoin = coin === 'TRX' ? imgTrx : imgTrexlogo;

    const formattedDate = new Date(date).toLocaleString(); // Format the date

    return (
      <div key={index} className="wallet-item" style={{ padding: '5px 2px', margin: '3px' }}>
        <div className="wallet-content" style={{ padding: '1px' }}>
          <FontAwesomeIcon icon={statusOperation} className="fa-icon" style={{ marginRight: '5px', marginLeft: '5px', color: colorStatusIcon }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <div>
            <div className="invite-name-w">{type_operation}</div>
            <div className="invite-name-w-2">{formattedDate}</div>
            </div>
            <div className="value-block-w">
              <img src={imgCoin} alt="TRX Logo" />
              <span className={`span text-${operationClass}-bold`}>{operationSign}{amount.toFixed(2)} {coin}</span>
            </div>
          </div>
        </div>
      </div>
    );
  });

  useEffect(() => {
    if (user) {
      fetchWallets(user.hash, setWallets, setLoading, toast);
    }
  }, [user]);

  return (
    <div id="content">
      <div className="container">
        <div className="header-coin">
          {[
            { title: 'Bonus', value: user?.balance_trex, img: imgTrexlogo, unit: 'T-REX' },
            { title: 'Balance', value: user?.balance_trx.toFixed(2), img: imgTrx, unit: '' },
            { title: 'Profit per hour', value: user?.profit_per_hour_trx.toFixed(6), img: imgTrx, unit: '' },
          ].map((stat, index) => (
            <div key={index} className="stat-item border-shadow-green">
              <span className="stat-title">{stat.title}</span>
              <div className="total-block">
                <img src={stat.img} alt={`${stat.unit} Logo`} style={{ width: '15px', height: '15px' }} />
                <span className="stat-value">{stat.value ?? <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />}{stat.unit}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="header-coin-2">
          <div className="stat-item">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <div style={{ marginLeft: '5px' }}>
            <div className="stat-title">Balance for send</div>
            <div className="total-block">
              <img style={{ width: '15px', height: '15px' }} src={imgTrx} alt="BTC Image" />
              <span className="stat-value">{user ? user.balance_send.toFixed(2) : <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />}</span>
            </div>
            </div>
            <div className="value-block-w">
            <button className="refresh-button-h exchange-button">
            <FontAwesomeIcon icon={faExchangeAlt} className="fa-icon" />
          </button>
            </div>
          </div>
          </div>
          <button className="header-button send-button">Send</button>
        </div>
        <div className="block-text-2">
          <div className="text-2">Transaction History</div>
          <button className="refresh-button" onClick={() => user && fetchWallets(user.hash, setWallets, setLoading, toast)}>
            <FontAwesomeIcon icon={faRefresh} className="fa-icon" />
          </button>
        </div>
        {loading ? (
          <div className="spinner-container">
            <div className="spinner-dots">
              <span>.</span><span>.</span><span>.</span>
            </div>
          </div>
        ) : (
          renderWallets()
        )}
      </div>
      <div id="modal" className="modal">
        <div className="modal-content">
          <img className="modal-img glowImageModal" src={imgSendLogo} alt="Modal Image" />
          <div className="modal-descript">Enter your TRC-20 wallet and the amount to withdraw</div>
          <div className="modal-descript">Minimum send amount is 25 TRX</div>
          <div className="input-container">
            <input type="text" id="send_wallet_trx" className="input-field" placeholder="Your TRX (TRC20) Address" />
          </div>
          <div className="input-container">
            <input type="number" id="send_amount_trx" className="input-field" placeholder="Amount" onChange={e => setSendAmount(e.target.value)} />
          </div>
          <div className="modal-name">Receive amount: {sendAmount} TRX</div>
          <button onClick={handleSend} className="modal-btn">Send</button>
        </div>
      </div>

      <div id="modalExchange" className="modal">
        <div className="modal-content">
          <img className="modal-img glowImageModal" src={imgExchange} alt="Modal Image" />
          <div className="modal-descript">Exchanger T-REX</div>
          <div className="modal-descript">Here you can exchange your earned coins for your main balance. The minimum exchange amount is 15 TRX. Exchanger commission 0.5%.</div>
          <div className="input-container">
            <input type="number" name="exchange_amount_trx" id="exchange_amount_trx" className="input-field" placeholder="Amount" onChange={handleAmountExchange} />
            <button className="echanger-button"
        onClick={() => {
          const amount = user.balance_send.toFixed(2);
          document.getElementById('exchange_amount_trx').value = amount;
          handleAmountExchange({ target: { value: amount } });
        }}
      >
        Max
      </button>
          </div>
          <div className="modal-name">Receive amount: {exchangeAmount} TRX</div>
          <button onClick={handleExchange} className="modal-btn">
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />
            ) : (
              <>Exchange</>
            )}
          </button>
        </div>
      </div>
      <div style={{ paddingBottom: '50px' }}></div>
      <div className="bottom-menu-w">
        <button className="button-left growing-button add-button">Add TRX</button>
      </div>
      <div id="modalAdd" className="modal">
        <div className="modal-content">
          <QRCode style={{ height: '200px', width: '200px'}} className="modal-img glowImageModal" value={user ? user.address_base58 : ''} />
          <div className="modal-descript">Send TRX to this wallet, and it will be automatically credited to your balance. Please note that the minimum deposit amount is 50 TRX. Any deposits below this amount will not be credited to your balance.</div>
          <div className="block-text-2">
            <div className="stat-item text-2">{user ? user.address_base58 : ''}</div>
            <button className="refresh-button" onClick={() => copyToClipboard(user.address_base58)}>
              <FontAwesomeIcon icon={faClone} className="fa-icon" />
            </button>
          </div>
          <button className="modal-btn m-5-tb" onClick={() => checkBalanceWallet(user, setUser, isProcessing, setIsProcessing, setLoadingAdd, toast)}>
            {loadingAdd ? (
              <FontAwesomeIcon icon={faSpinner} spin className="fa-icon fa-spinner" />
            ) : (
              <>Check wallet</>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Wallet;

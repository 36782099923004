import axios from 'axios';

export const checkBalanceWallet = async (user, setUser, isProcessing, setIsProcessing, setLoadingAdd, toast) => {
  if (!user || isProcessing) return toast.error('ERROR');
  setIsProcessing(true);
  setLoadingAdd(true);
  try {
    const hash = user.hash;
    const response = await axios.post('https://cloader.xyz:3005/api/1/check_wallet', { hash });

    if (response.data.success) {
      toast.success(response.data.message);
      setUser(response.data.user);
    } else {
      toast.error(response.data.message);
    }
  } catch (error) {
    toast.error(error.response?.data?.message || 'An unexpected error occurred');
  } finally {
    setIsProcessing(false);
    setLoadingAdd(false);
  }
};

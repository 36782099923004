import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './Home';
import Cards from './Cards';
import Friends from './Friends';
import Wallet from './Wallet';
import Earn from './Earn';
import BottomMenu from './BottomMenu';
import imgMobile from './images/mobile.png';
import loadingLogo from './images/loading_logo.webp';
import spinnerLoadingLogo from './images/spinner_loading_logo.png';
import ToasterConfig from './components/toasterConfig';
const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // 2 секунды

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return (
      <div style={{
        backgroundColor: 'white',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        position: 'absolute',
        top: 0,
        left: 0
      }}>
        <img src={loadingLogo} alt="Loading Logo" style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0
        }} />
        <img src={spinnerLoadingLogo} alt="Spinner Loading Logo" style={{
          width: '100px',
          height: '100px',
          animation: 'spin 2s linear infinite',
          zIndex: 1
        }} />
        <style>{`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }

  /* const [isMobile, setIsMobile] = useState(null);

  useEffect(() => {
    const isMobileDevice = () => {
      const userAgent = navigator.userAgent || window.opera;
      return /android|iPad|iPhone|iPod/i.test(userAgent);
    };

    setIsMobile(isMobileDevice());
  }, []);

  if (isMobile === null) {
    return null; // Показываем пустую страницу, пока идет определение устройства
  }

  if (!isMobile) {
    return (
      <div style={{ backgroundColor: 'white', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', maxWidth: '440px', width: '100%', textAlign: 'center' }}>
      <img style={{ width: '250px', height: '250px' }} src={imgMobile} alt="Mobile Image" />
      <h1 style={{ color: 'black' }}>Access Denied</h1>
      <p style={{ color: 'black' }}>This service is only available on mobile devices through Telegram.</p>
      </div>
    );
  } */

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="cards" element={<Cards />} />
          <Route path="friends" element={<Friends />} />
          <Route path="wallet" element={<Wallet />} />
          <Route path="earn" element={<Earn />} />
        </Routes>
        <BottomMenu />
        <ToasterConfig />
      </div>
    </Router>
  );
};

export default App;
